export default function cookiesReducer(cookies, action) {
  switch (action.type) {
    case "OPEN_MANAGEMENT":
      console.log("open management")
      return {
        ...cookies,
        isManagementOpen: true,
      }
    case "CLOSE_MANAGEMENT":
      console.log("close management")
      return {
        ...cookies,
        isManagementOpen: false,
      }
    case "ACCEPT_COOKIES":
      return {
        ...cookies,
        essential: true,
        analytics: true,
        marketing: true,
        social: true,
      }
    case "REFUSE_COOKIES":
      return {
        ...cookies,
        essential: true,
        analytics: false,
        marketing: false,
        social: false,
      }
    case "ACCEPT_ANALYTICS":
      return {
        ...cookies,
        analytics: true,
      }
    case "REFUSE_ANALYTICS":
      return {
        ...cookies,
        analytics: false,
      }
    case "ACCEPT_MARKETING":
      return {
        ...cookies,
        marketing: true,
      }
    case "REFUSE_MARKETING":
      return {
        ...cookies,
        marketing: false,
      }
    case "ACCEPT_FUNCTIONAL":
      return {
        ...cookies,
        functional: true,
      }
    case "REFUSE_FUNCTIONAL":
      return {
        ...cookies,
        functional: false,
      }
    case "ACCEPT_SOCIAL":
      return {
        ...cookies,
        social: true,
      }
    case "REFUSE_SOCIAL":
      return {
        ...cookies,
        social: false,
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function getStoredCookies() {
  try {
    const cookies = localStorage.getItem("consent")
    return cookies
      ? JSON.parse(cookies)
      : {
          essential: true,
          analytics: false,
          marketing: false,
          social: false,
          isManagementOpen: false,
        }
  } catch (error) {
    console.error(error)
    return {
      essential: true,
      analytics: false,
      marketing: false,
      social: false,
      isManagementOpen: false,
    }
  }
}

export const storeCookies = cookies => {
  localStorage?.setItem("consent", JSON.stringify(cookies))
}

export const initialCookies = getStoredCookies()
