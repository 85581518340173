import React, { useReducer, createContext } from "react"
import cookiesReducer, { initialCookies } from "./cookies-reducer"

export const CookiesContext = createContext(cookiesReducer)

export const CookiesProvider = ({ children }) => {
  const [cookies, dispatch] = useReducer(cookiesReducer, initialCookies)

  return (
    <CookiesContext.Provider value={{ cookies, dispatch }}>
      {children}
    </CookiesContext.Provider>
  )
}
