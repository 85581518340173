/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import "./src/styles/global.css"
import { AnimatePresence } from "framer-motion"
import { CookiesProvider } from "./src/components/GDPR/CookieContext"

export const wrapPageElement = ({ element }) => (
  <CookiesProvider>
    <AnimatePresence exitBeforeEnter={true}>{element}</AnimatePresence>
  </CookiesProvider>
)
